import resumeData from './skills_data.json';
import interestsAndPersonalSkills from './interests_personal_skills.json';
import educationData from './education.json';
export default function ResumeInfo() {
    return (

        <aside className="resume-aside col-4">

            <section>
                <div className="row align-items-center">
                    <div className="resume-title col-12">
                        <h2 className="resume-name mb-0">Ibrahim Taisir Awad</h2>
                    </div>
                    <div className="resume-contact col-12">
                        <ul className="list-unstyled mb-0">
                            <li className="ml-1"><i className="fa fa-phone-square fa-fw fa-lg mr-2 "></i><a className="resume-link" href="tel:+(31) 687 243 638">+(31) 687 243 638</a></li>
                            <li className="ml-1"><i className="fa fa-whatsapp fa-fw fa-lg mr-2 "></i><a className="resume-link" href="https://wa.me/31687243638">Click to send WhatsApp</a></li>
                            <li className="ml-1"><i className="fa fa-envelope-square fa-fw fa-lg mr-2"></i><a className="resume-link" href="mailto:itaisir.awad@gmail.com">itaisir.awad@gmail.com</a></li>
                            <li className="ml-1"><i className="fa fa-map-marker fa-fw fa-lg mr-2"></i>Delfzijl, Netherlands</li>
                            <li className="ml-1"><i className="fa fa-linkedin-square fa-fw fa-lg mr-2"></i><a className="resume-link" href="https://www.linkedin.com/in/itaisir/">linkedin.com/in/itaisir/</a></li>
                            <li className="ml-1"><i className="fa fa-github-square fa-fw fa-lg mr-2"></i><a className="resume-link" href="https://github.com/itaisir">github.com/itaisir/</a></li>
                            <li className="ml-1"><i className="fa fa-gitlab fa-fw fa-lg mr-2"></i><a className="resume-link" href="https://gitlab.com/itaisir">gitlab.com/itaisir/</a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <hr />
            <section className="skills-section py-1">
                <h3 className="text-uppercase resume-section-heading mb-3">Skills</h3>
                {resumeData.map((data) => {
                    return (
                        <div key={data.title} className="item">
                            <h4 className="item-title mb-1">{data.title}</h4>
                            <ul className="list-unstyled resume-skills-list">
                                {data.skills.map((skill) => (
                                    <li key={skill}>{skill}</li>
                                ))}
                            </ul>
                        </div>
                    )
                })}
            </section>

            {educationData.map((edu) => (
                <section key={edu.title} className="education-section py-1">
                    <h3 className="text-uppercase resume-section-heading mb-3">{edu.title}</h3>
                    <ul className="list-unstyled resume-education-list">
                        {edu.schools.map((school) => (
                            <li key={school.schoolTitle} className="mb-3">
                                <div className="resume-degree font-weight-bold">{school.schoolTitle}</div>
                                <div className="resume-degree-org text-muted">{school.schoolLocation}</div>
                                {school.graduationProject && <div className="resume-degree-org text-muted">{school.graduationProject}</div>}
                                <div className="resume-degree-time text-muted">{school.schoolDuration}</div>
                            </li>
                        ))}
                    </ul>
                </section>
            ))}
            <section className="skills-section py-1">
                <h3 className="text-uppercase resume-section-heading mb-2">Languages</h3>
                <ul className="list-unstyled resume-lang-list">
                    <li>Arabic <span className="text-muted">(Native)</span></li>
                    <li>English <span className="text-muted">(Very Good)</span></li>
                    <li>Turkish <span className="text-muted">(Good)</span></li>
                    <li>French <span className="text-muted">(Fair)</span></li>
                </ul>
            </section>
            {interestsAndPersonalSkills.map((item) => (
                <section key={item.title} className="skills-section py-1">
                    <h3 className="text-uppercase resume-section-heading mb-2">{item.title}</h3>
                    <ul className="list-unstyled resume-interests-list mb-0">
                        {item.items.map(innerItem => (
                            <li key={innerItem}>{innerItem}</li>
                        ))}
                    </ul>
                </section>
            ))}

        </aside>
    )
}