import React, { useState, useEffect } from "react";
import "./App.css";
import WorkExperienceAndProjects from "./WorkExperienceAndProjects";
import ResumeInfo from "./ResumeInfo";
import Splash from "./Splash/Splash";

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading time
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the duration as needed
  }, []);

  return (
    <div>
      {loading ? (
        <Splash /> // Show splash screen while loading
      ) : (
        <article className="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5">
          <div className="resume-body row">
            <WorkExperienceAndProjects />
            <ResumeInfo />
          </div>
        </article>
      )}
    </div>
  );
}

export default App;
